<template>
	<main>
		<HeaderTab 
			:title="getTrad('monte.contract_new')"
		/>
				
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<ContractForm v-if="show_form" :contract_id="contract_id" :stallion_id="stallion_id" :mare_id="mare_id"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
	import ContractMixin from "@/mixins/Contract.js"

	export default {
		name: "ContractNew",
		mixins: [ContractMixin],
		data () {
			return {
				show_form: false,
				stallion_id: null,
				mare_id: null,
				contract_id: null
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				const contract = await this.addContract()
				this.contract_id = contract.contract_id

				if(this.$route.params.stallion_id) {
					this.stallion_id = parseInt(this.$route.params.stallion_id)
				}
				if(this.$route.params.mare_id) {
					this.mare_id = parseInt(this.$route.params.mare_id)
				}
				this.show_form = true
			},
		},
		components: {
			ContractForm : () => import('@/components/Contract/ContractForm'),
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
		},
	}
</script>